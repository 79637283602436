import React, { useReducer } from "react";
import CustomerContext from "./customersContext";
import CustomerReducer from "./customersReducer";
import { handleNotications } from "../../../api/util";
import {
    update_vehicle,
    add_vehicle,
    add_customer_location,
    search_customer,
    update_customer_info,
    update_customer_location,
    create_customer,
    update_customer_status,
    agent_orders,
    driver_orders,
    list_drivers,
    list_vehicles,
    list_customers,
    list_customer_locations,
    list_products,
    search_vehicle_by_reg_no,
} from "../../../api/bulk";

import {
    ADD_CUSTOMER,
    UPDATE_CUSTOMER,
    CUSTOMER_ERROR,
    GET_CUSTOMERS,
    GET_CUSTOMER,
    LIST_CUSTOMERS,
    GET_AGENT_ORDERS,
    GET_DRIVER_ORDERS,
    ADD_CUSTOMER_LOCATION,
    AGENT_ORDERS_ERROR,
    DRIVER_ORDERS_ERROR,
    SEARCH_CUSTOMER,
    CLEAR_NOTIFICATION,
    LIST_VEHICLES,
    LIST_DRIVERS,
    SEARCH_VEHICLE,
    UPDATE_VEHICLE,
    GET_CUSTOMERS_LOCATIONS,
} from "../../types";

const CustomerState = (props) => {
    const initialState = {
        customers: [],
        customer_locations: [],
        current: null,
        filtered: null,
        error: null,
        drivers: [],
        vehicles: [],
        current_vehicle: null,
        notification: null,
    };

    const [state, dispatch] = useReducer(CustomerReducer, initialState);

    // Get Customers
    const getCustomers = async () => {
        const res = await list_customers();
        console.log(res, "res");
        if (res.responseCode === 1) {
            dispatch({ type: GET_CUSTOMERS, payload: res.responseObject });
        }
        else{
            dispatch({ type: GET_CUSTOMERS, payload: [] });
        }
    };

    const getCustomerLocations = async () => {
        const res = await list_customer_locations();
        if (res.responseCode === 1) {
            dispatch({
                type: GET_CUSTOMERS_LOCATIONS,
                payload: res.responseObject,
            });
        }
        else {
            dispatch({
                type: GET_CUSTOMERS_LOCATIONS,
                payload: [],
            });
        }
    };

    // list products
    const listProducts = async () => {
        const res = await list_products();
        if (res.responseCode === 1) {
            dispatch({ type: LIST_CUSTOMERS, payload: res.responseObject });
        } else {
            dispatch({ type: LIST_CUSTOMERS, payload: [] });
        }
    };

    // agent orders
    const agentOrders = async () => {
        const res = await agent_orders();
        if (res.responseCode === 1) {
            dispatch({ type: GET_AGENT_ORDERS, payload: res.responseObject });
        } else {
            dispatch({ type: GET_AGENT_ORDERS, payload: [] });
        }
    };

    // driver orders
    const driverOrders = async () => {
        const res = await driver_orders();
        if (res.responseCode === 1) {
            dispatch({ type: GET_DRIVER_ORDERS, payload: res.responseObject });
        } else {
            dispatch({ type: GET_DRIVER_ORDERS, payload: [] });
        }
    };

    // list drivers
    const listDrivers = async () => {
        const res = await list_drivers();
        console.log(res, "res");
        if (res.responseCode === 1) {
            dispatch({ type: LIST_DRIVERS, payload: res.responseObject });
        } else {
            dispatch({ type: LIST_DRIVERS, payload: [] });
        }
    };

    // list vehicles
    const listVehicles = async () => {
        const res = await list_vehicles();
        if (res.responseCode === 1) {
            dispatch({ type: LIST_VEHICLES, payload: res.responseObject });
        }
    };

    // Create Customer
    const addCustomer = async (customer) => {
        const res = await create_customer(customer);
        if (res.responseCode === 1) {
            dispatch({ type: ADD_CUSTOMER, payload: res.responseMessage });
        } else {
            dispatch({ type: CUSTOMER_ERROR, payload: res.responseMessage });
        }
    };

    // Update Customer status
    const updateCustomerStatus = async (customerId, customer) => {
        const res = await update_customer_status(customerId, customer);
        if (res.responseCode === 1) {
            dispatch({ type: UPDATE_CUSTOMER, payload: res.responseMessage });
        } else {
            dispatch({ type: CUSTOMER_ERROR, payload: res.responseMessage });
        }
    };

    // update customer
    const updateCustomer = async (customerId, formData) => {
        const res = await update_customer_info(customerId, formData);
        console.log(res, "res");
        if (res.responseCode === 1) {
            dispatch({ type: UPDATE_CUSTOMER, payload: res.responseMessage });
        } else {
            dispatch({ type: CUSTOMER_ERROR, payload: res.responseMessage });
        }
    };

    // update customer location
    const updateCustomerLocation = async (location) => {
        const response = await update_customer_location(location);
        if (response && response.responseCode === 1) {
            dispatch({ type: ADD_CUSTOMER, payload: response.responseMessage });
        } else {
            dispatch({
                type: CUSTOMER_ERROR,
                payload: response.responseMessage,
            });
        }
    };

    // search customer
    const searchCustomer = async (text) => {
        const response = await search_customer(text);
        if (response && response.responseCode === 1) {
            dispatch({
                type: SEARCH_CUSTOMER,
                payload: response.responseObject,
            });
        } else {
            dispatch({
                type: SEARCH_CUSTOMER,
                payload: []
            });
        }
    };

    // add customer location
    const addCustomerLocation = async (location) => {
        const response = await add_customer_location(location);
        console.log(response, "response");
        if (response && response.responseCode === 1) {
            dispatch({ type: ADD_CUSTOMER, payload: response.responseMessage });
        } else {
            dispatch({
                type: CUSTOMER_ERROR,
                payload: response.responseMessage,
            });
        }
    };

    // Add vehicle
    const AddVehicle = async (vehicle) => {
        const response = await add_vehicle(vehicle);
        console.log(response, "response");
        if (response && response.responseCode === 1) {
            dispatch({ type: ADD_CUSTOMER, payload: response.responseMessage });
        } else {
            dispatch({
                type: CUSTOMER_ERROR,
                payload: response.responseMessage,
            });
        }
    };

    // update vehicle
    const UpdateVehicle = async (vehicle) => {
        const response = await update_vehicle(vehicle);

        if (response && response.responseCode === 1) {
            dispatch({
                type: UPDATE_VEHICLE,
                payload: response.responseMessage,
            });
        } else {
            dispatch({
                type: CUSTOMER_ERROR,
                payload: response.responseMessage,
            });
        }
    };

    // search vehicle
    const searchVehicle = async (text) => {
        const response = await search_vehicle_by_reg_no(text);
        if (response && response.responseCode === 1) {
            dispatch({
                type: SEARCH_VEHICLE,
                payload: response.responseObject,
            });
        } else {
            dispatch({
                type: CUSTOMER_ERROR,
                payload: response.responseMessage,
            });
        }
    };

    const clear_notifications = () => {
        dispatch({ type: CLEAR_NOTIFICATION });
    };

    return (
        <CustomerContext.Provider
            value={{
                customers: state.customers,
                filtered: state.filtered,
                error: state.error,
                current: state.current,
                drivers: state.drivers,
                vehicles: state.vehicles,
                current_vehicle: state.current_vehicle,
                notification: state.notification,
                customer_locations: state.customer_locations,
                getCustomers,
                addCustomer,
                updateCustomerStatus,
                updateCustomer,
                searchCustomer,
                addCustomerLocation,
                AddVehicle,
                UpdateVehicle,
                searchVehicle,
                listProducts,
                agentOrders,
                driverOrders,
                listDrivers,
                listVehicles,
                clear_notifications,
                getCustomerLocations,
                updateCustomerLocation,
            }}
        >
            {props.children}
        </CustomerContext.Provider>
    );
};

export default CustomerState;
