// Importing Libraries
import React, { useEffect, useContext } from 'react';
import { Route, Routes } from 'react-router-dom'
// Importing Pages

import PageDashboard from './pages/PageDashboard';
import PageLogin from './pages/PageLogin';
import RegisterUsers from './pages/Authentication/RegisterUsers';
import Customer from './pages/customers/customer';


import AddOrders from './pages/Orders/AddOrders';
import ListOrders from './pages/Orders/ListOrders';
import PageError404 from './pages/PageErorr404';
import AuthState from './context/auth/authState';
import AlertState from './context/alerts/alertState';
import OrderState from './context/bulk/orders/ordersState';
import UserState from './context/users/userState';
import CustomerState from './context/bulk/customers/customerState';
import Users from './components/admin/users';
import BulkOrders from './components/bulk/orders';


import ProtectedRoute from './components/protectedRoute';
import ListCustomers from './pages/customers/ListCustomers';
import AddCustomers from './pages/customers/addCutomers';
// import AddCustomerLocation from './pages/customers/addCutomerLocation';
import ListVehicles from './pages/customers/ListVehicles';
import AddVehicle from './pages/customers/addVehicle';
import ListDrivers from './pages/drivers/ListDrivers';
import ListDeliveryPlans from './pages/Orders/ListDeliveryPlans';
import RequestOtp from './pages/Authentication/forgotPassword';
import ResetPassword from './pages/Authentication/ResetPassWord';
import ListCustomerLocations from './pages/customers/customerLocations';

import AuthContext from './context/auth/authContext';
import { get } from 'react-hook-form';
import UserProfile from './pages/Authentication/Profile';
import ListRoles from './pages/Authentication/ManageRoles';
import ChangePassword from './components/auth/changePassword';

// load roles

let token = localStorage.getItem("token");
function App() {
  const authContext = useContext(AuthContext);
  const { loadUser } = authContext || {}; // Using optional chaining
  

  useEffect(() => {
    if (token) {
        loadUser()
    }
  }, [token]);


  return (
    // <AuthState>
      <UserState>
        <CustomerState>
          <OrderState>
            <AlertState>
              <React.Fragment>
                <Routes>
                  {/*  Protected Route */}

                  <Route path="*" element={<ProtectedRoute element={<PageDashboard />} />} />
                  <Route path="/addorders" element={<AddOrders />} />

                  {/* Otogas Routes */}
                  <Route path="/bulk/sales" element={<bulkSales />} />
                  <Route path="/bulk/sales/add" element={<bulkSales />} />
                  <Route path="/bulk/users" element={<bulkSales />} />

                  {/* <Route path="/bulk/customers/:id/add_customer_location" element={<AddCustomerLocation />} /> */}



                  {/* Bulk Routes */}

                  <Route path="/bulk/orders" element={<ProtectedRoute element={<ListOrders />} />} />
                  <Route path="/bulk/orders/add" element={<ProtectedRoute element={<AddOrders />} />} />
                  <Route path="/bulk/trips" element={<ProtectedRoute element={<BulkOrders />} />} />
                  <Route path="/bulk/drivers" element={<ProtectedRoute element={<ListDrivers />} />} />
                  <Route path="/bulk/drivers/add" element={<ProtectedRoute element={<RegisterUsers />} />} />
                  <Route path="/bulk/delivery-plans" element={<ProtectedRoute element={<ListDeliveryPlans />} />} />
                  <Route path="/bulk/customers/add" element={<ProtectedRoute element={< AddCustomers />} />} />
                  <Route path="/bulk/vehicles" element={<ProtectedRoute element={<ListVehicles />} />} />
                  <Route path="/bulk/vehicles/add" element={<ProtectedRoute element={<AddVehicle />} />} />
                  <Route path="/bulk/vehicles/:vehicleId/edit" element={<ProtectedRoute element={<AddVehicle />} />} />
                  <Route path="/bulk/customers" element={<ProtectedRoute element={<ListCustomers />} />} />
                  <Route path="/bulk/customers/:customerId" element={<ProtectedRoute element={<Customer />} />} />
                  <Route path="/bulk/customers/:customerId/edit" element={<ProtectedRoute element={<AddCustomers />} />} />
                  <Route path="/bulk/locations" element={<ProtectedRoute element={<ListCustomerLocations />} />} />

                  {/* Auth Routes */}
                  <Route path="/login" element={<PageLogin />} />
                  <Route path="/forgot-password" element={<RequestOtp />} />
                  <Route path="/otp-verification" element={<ResetPassword />} />



                  {/* Users Routes */}
                  <Route path="/users" element={<Users />} />
                  <Route path="/users/edit/:id" element={<RegisterUsers />} />
                  <Route path="/users/create" element={<RegisterUsers />} />

                  <Route path="/users/roles" element={<ProtectedRoute element={<ListRoles />} />} />
                  <Route path="/users/profile" element={<ProtectedRoute element={<UserProfile />} />} />
                  <Route path="/change-password" element={<ChangePassword />} />


                  <Route path="*" element={<PageError404 />} />
                  {/* Protected Route */}

                  {/* utils */}
                </Routes>
              </React.Fragment>
            </AlertState>
          </OrderState>
        </CustomerState>
      </UserState>
    // </AuthState>
  );
}

export default App;
