import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import CustomersContext from "../../context/bulk/customers/customersContext";
import SweetAlertWrapper from "../../components/Layout/SweetAlert";
import Select from "react-select";

const AddLocation = ({ show, handleClose, editLocation }) => {
    const customerContext = useContext(CustomersContext);
    const {
        addCustomerLocation,
        updateCustomerLocation,
        notification,
        clear_notifications,
        customers,
        getCustomers,
    } = customerContext;

    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const [location, setLocation] = useState({
        customerId: "",
        locationName: "",
        latitude: 0,
        longitude: 0,
    });

    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    useEffect(() => {
        getCustomers();
    }, []);

    useEffect(() => {
        if (editLocation) {
            console.log(editLocation, "nejkfnrjekfn");
            setLocation(editLocation);
        } else {
            setLocation({
                customerId: "",
                locationName: "",
                latitude: 0,
                longitude: 0,
            });
        }
    }, [editLocation, show]);

    const onChange = (e) => {
        const { name, value } = e.target;
        setLocation({
            ...location,
            [name]: value,
        });
    };

    const handleCustomerChange = (selectedOption) => {
        setLocation({
            ...location,
            customerId: selectedOption.value,
        });
    };

    const handleConfirm = () => {
        setShowAlert(false);
        clear_notifications();
        handleClose();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (editLocation) {
            updateCustomerLocation(location);
        } else {
            addCustomerLocation(location);
        }
        handleClose(); // Close the modal after submitting
    };

    useEffect(() => {
        if (!show) {
            setLocation({
                customerId: "",
                locationName: "",
                latitude: 0,
                longitude: 0,
            });
            setAlertMessage("");
            setShowAlert(false);
        }
    }, [show]);

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editLocation
                            ? "Edit Customer Location"
                            : "Add Customer Location"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SweetAlertWrapper
                        show={showAlert}
                        title="Notification"
                        message={alertMessage.text}
                        onConfirm={handleConfirm}
                        confirmBtnText="OK"
                        type={alertMessage.type}
                    />
                    <Form onSubmit={onSubmit}>
                        <Form.Group controlId="customerId">
                            <Form.Label>Select Customer</Form.Label>
                            <Select
                                name="customerId"
                                options={customers.map((customer) => ({
                                    value: customer.customerId,
                                    label: customer.customerName,
                                }))}
                                value={
                                    customers
                                        .map((customer) => ({
                                            value: customer.customerId,
                                            label: customer.customerName,
                                        }))
                                        .find(
                                            (customer) =>
                                                customer.value ===
                                                location.customerId,
                                        ) || null
                                }
                                onChange={handleCustomerChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="locationName">
                            <Form.Label>Location Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="locationName"
                                value={location.locationName}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="latitude">
                            <Form.Label>Latitude</Form.Label>
                            <Form.Control
                                type="text"
                                name="latitude"
                                value={location.latitude}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="longitude">
                            <Form.Label>Longitude</Form.Label>
                            <Form.Control
                                type="text"
                                name="longitude"
                                value={location.longitude}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {editLocation ? "Update" : "Submit"}
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddLocation;
