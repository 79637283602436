import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import CustomersContext from "../../context/bulk/customers/customersContext";
import Header from "../../components/Layout/Header";
import Main from "../../components/Layout/Main";
import Sidebar from "../../components/Layout/Sidebar";
import SweetAlertWrapper from "../../components/Layout/SweetAlert";

const AddVehicle = () => {
    const { vehicleId } = useParams();
    const navigate = useNavigate();

    const customerContext = useContext(CustomersContext);
    const { AddVehicle, UpdateVehicle, current_vehicle, notification, clear_notifications, searchVehicle } = customerContext;

    const page = "Add Vehicle";

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: '', type: '' });

    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors, isValid },
        reset
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            vehicleType: "",
            vehicleModel: "",
            registrationNumber: "",
            tankCapacity: 0,
            tareWeight: 0,
            vehicleMake: ""
        }
    });

    // Fetch vehicle data if editing
    useEffect(() => {
        if (vehicleId) {
            searchVehicle(vehicleId);
        }
    }, [vehicleId]);

    useEffect(() => {
        if (current_vehicle) {
            reset(current_vehicle);
        }
    }, [current_vehicle, reset]);

    // Sweet alert confirmation
    const handleConfirm = () => {
        setShowAlert(false);
        if (notification.type === "success") {
            navigate('/bulk/vehicles');
        }
        clear_notifications();
    };

    useEffect(() => {
        if (notification) {
            setAlertMessage({ text: notification.text, type: notification.type });
            setShowAlert(true);
        }
    }, [notification]);

    const onSubmit = (data) => {
        if (vehicleId) {
            UpdateVehicle({ ...data, id: vehicleId });
        } else {
            AddVehicle(data);
        }
    };

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page={page} />
            <Main>
                {/* <SweetAlertWrapper
                    show={showAlert}
                    title="Success"
                    message={alertMessage.text}
                    onConfirm={handleConfirm}
                    confirmBtnText="OK"
                    type={alertMessage.type}
                /> */}
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4 d-flex justify-content-between align-items-center">
                                        <div>
                                            <h5 className="card-title">{vehicleId ? "Edit Vehicle" : "Add Vehicle"}</h5>
                                        </div>
                                        <div>
                                            <a href="/bulk/vehicles" className="btn btn-primary">Back</a>
                                        </div>
                                    </div>
                                    <div className="col-md-4"></div>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-group">
                                        <label htmlFor="vehicleType">Vehicle Type</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.vehicleType ? 'is-invalid' : ''}`}
                                            id="vehicleType"
                                            {...register("vehicleType", { required: "Vehicle Type is required" })}
                                        />
                                        {errors.vehicleType && <div className="invalid-feedback">{errors.vehicleType.message}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="vehicleModel">Vehicle Model</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.vehicleModel ? 'is-invalid' : ''}`}
                                            id="vehicleModel"
                                            {...register("vehicleModel", { required: "Vehicle Model is required" })}
                                        />
                                        {errors.vehicleModel && <div className="invalid-feedback">{errors.vehicleModel.message}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="registrationNumber">Registration Number</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.registrationNumber ? 'is-invalid' : ''}`}
                                            id="registrationNumber"
                                            {...register("registrationNumber", { required: "Registration Number is required" })}
                                        />
                                        {errors.registrationNumber && <div className="invalid-feedback">{errors.registrationNumber.message}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="tankCapacity">Tank Capacity</label>
                                        <input
                                            type="number"
                                            className={`form-control ${errors.tankCapacity ? 'is-invalid' : ''}`}
                                            id="tankCapacity"
                                            {...register("tankCapacity", {
                                                required: "Tank Capacity is required",
                                                min: { value: 0, message: "Tank Capacity must be a positive number" }
                                            })}
                                        />
                                        {errors.tankCapacity && <div className="invalid-feedback">{errors.tankCapacity.message}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="tareWeight">Tare Weight</label>
                                        <input
                                            type="number"
                                            className={`form-control ${errors.tareWeight ? 'is-invalid' : ''}`}
                                            id="tareWeight"
                                            {...register("tareWeight", {
                                                required: "Tare Weight is required",
                                                min: { value: 0, message: "Tare Weight must be a positive number" }
                                            })}
                                        />
                                        {errors.tareWeight && <div className="invalid-feedback">{errors.tareWeight.message}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="vehicleMake">Vehicle Make</label>
                                        <input
                                            type="text"
                                            className={`form-control ${errors.vehicleMake ? 'is-invalid' : ''}`}
                                            id="vehicleMake"
                                            {...register("vehicleMake", { required: "Vehicle Make is required" })}
                                        />
                                        {errors.vehicleMake && <div className="invalid-feedback">{errors.vehicleMake.message}</div>}
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={!isValid} // Disable button if the form is not valid
                                    >
                                        {vehicleId ? "Update" : "Submit"}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </Main>
        </div>
    );
};

export default AddVehicle;
