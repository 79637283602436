import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CLEAR_ERRORS,
    GET_USERS,
    CLEAR_NOTIFICATION,
    LIST_APPS,
    LIST_ROLES,
    LIST_USER_ROLES,
    GET_USER_DETAILS_FROM_TOKEN,
    ASSIGN_ROLES,
    AUTH_WARNING,
    AUTHENTICATION_ERROR,
    AUTH_SUCCESS,
    LIST_ROLE_PERMISSIONS,
    LIST_ROLE_USERS

} from "../types";


export default (state, action) => {
    switch (action.type) {
        case USER_LOADED:
            localStorage.setItem("isAuthenticated", true)
            localStorage.setItem("user", action.payload.user)
            return {
                ...state,
                isAuthenticated: true,
                loading: false,
                user: action.payload.user
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case LOGIN_SUCCESS:

            try {
                localStorage.setItem("token", action.payload.token);
                console.log("Token set successfully");
            } catch (error) {
                console.error("Error setting token in localStorage", error);
            }

            console.log(action.payload, "payload ++++++++++++++")

            // drop user from the payload

            // let { user,roles, ...rest } = action.payload;
            localStorage.setItem("token", action.payload.token)

            return {
                ...state,
                isAuthenticated: true,
                loading: false,
            };
        case REGISTER_FAIL:
            return {
                ...state,
                loading: false,
            }

        case AUTHENTICATION_ERROR:
            return {
                ...state,
                error: action.payload,
            }

        case AUTH_SUCCESS:
            return {
                ...state,
                notification: {
                    type: "success",
                    text: action.payload
                }
            }

        case AUTH_ERROR:
            return {
                ...state,
                notification: {
                    type: "error",
                    text: action.payload
                }
            }

        case AUTH_WARNING:
            return {
                ...state,
                notification: {
                    type: "warning",
                    text: action.payload
                }
            }
        case LOGIN_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        case LOGOUT:
            localStorage.clear();
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                loading: false,
                user: null,
                error: action.payload
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        case GET_USERS:
            return {
                ...state,
                users: action.payload
            };
        case CLEAR_NOTIFICATION:
            return {
                ...state,
                notification: null
            };

        case LIST_APPS:
            return {
                ...state,
                apps: action.payload
            };

        case GET_USER_DETAILS_FROM_TOKEN:
            const user_roles = action.payload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            return {
                ...state,
                user_roles: user_roles,

                user: {
                    name: action.payload.Name,
                    user_code: action.payload.UserCode,
                    email: action.payload.Email,
                }

            };

        case LIST_ROLES:
            return {
                ...state,
                roles: action.payload
            };

        case ASSIGN_ROLES:
            return {
                ...state,
                notification: {
                    type: "success",
                    text: action.payload
                }
            }
        case LIST_ROLE_PERMISSIONS:
            return {
                ...state,
                role_permissions: action.payload
            }

        case LIST_ROLE_USERS:
            return {
                ...state,
                role_users: action.payload
            }


        default:
            return state;
    }
};


// {
//     "UniqueId": "381dc04e-cf2a-48fa-aeb0-e093617ed055",
//     "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier": "5cc7e392-06b2-4d3e-9097-ba2e89d79874",
//     "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname": "Benedict",
//     "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname": "Mwendwa",
//     "username": "Bmwendwa@protoenergy.com",
//     "id": "5cc7e392-06b2-4d3e-9097-ba2e89d79874",
//     "Name": "Benedict Mwendwa Mwendwa",
//     "PayrollNumber": "KABC3827",
//     "PhoneNumber": "+254715357867",
//     "UserCode": "00004",
//     "Email": "bmwendwa@protoenergy.com",
//     "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": [
//         [
//     "POST:Sales:AddSale",
//     "GET:Stock:GetStockTakes",
//     "POST:Bulk:AddCustomerLocation",
//     "GET:Bulk:BulkDrivers",
//     "POST:Bulk:DeliverOrders",
//     "POST:Station:UpdateDispenser",
//     "POST:Payments:AddTill",
//     "POST:Sales:TransferSaleToAnotherNozzle",
//     "POST:Sales:ReverseSale",
//     "GET:Customer:GetAllComplains",
//     "POST:Bulk:UpdateCustomerInfo",
//     "GET:Bulk:DriverOrders",
//     "GET:Bulk:DeliveryPlans",
//     "GET:Bulk:DriverDashBoard",
//     "GET:Payments:GetAllTills",
//     "GET:Station:GetAllNozzles",
//     "POST:Customer:DeactivateVehicle",
//     "GET:Bulk:ListAllCustomers",
//     "GET:Bulk:CustomersList",
//     "POST:Station:UpdateTank",
//     "POST:Customer:AddComplain",
//     "POST:UserRoles:AssignPermissionsToRole",
//     "POST:Station:AddStation",
//     "POST:Customer:AddVehicle",
//     "GET:Bulk:AgentDashBoard",
//     "POST:Customer:AddCustomer",
//     "GET:Station:GetAllTanks",
//     "POST:Station:AddTank",
//     "DELETE:Bulk:RemoveOrder",
//     "POST:UserRoles:AssignUserToRole",
//     "GET:Stock:ShiftVariances",
//     "GET:Station:GetAllDispensers",
//     "GET:Station:GetAllStations",
//     "POST:Station:UpdateNozzle",
//     "POST:UserRoles:RemoveRoleFromUser",
//     "POST:Payments:UpdateTill",
//     "GET:Customer:GetAllVehicles",
//     "POST:Bulk:AddPlan",
//     "POST:Stock:StockTake",
//     "PUT:Bulk:PlanAssignMents",
//     "POST:Station:AssignDispenser",
//     "POST:Station:UpdateStation",
//     "GET:Stock:ShiftSales",
//     "PATCH:Customer:UpdateVehicle",
//     "GET:Bulk:GetDeliveredOrdersStatistics",
//     "POST:Customer:ActivateVehicle",
//     "GET:Bulk:WebDashBoard",
//     "GET:Bulk:DeliveredOrders",
//     "POST:Bulk:SearchCustomer",
//     "POST:Bulk:AttachOrder",
//     "GET:Stock:ShiftStatus",
//     "POST:Payments:AssignTillToDispenser",
//     "GET:Customer:SearchVehicle",
//     "POST:Bulk:BulkMangerApproval",
//     "GET:Bulk:UnattachedOrders",
//     "POST:Bulk:LoadOrders",
//     "POST:Bulk:CancelDeliveryPlan",
//     "POST:Stock:RefreshASale",
//     "Post:Authentication:UpdateUserDetails",
//     "Post:Authentication:UpdateUserDetails",
//     "POST:Bulk:AddOrder",
//     "POST:Bulk:FinanceApproval",
//     "POST:Stock:InitialStockTake",
//     "POST:Bulk:AddProduct",
//     "POST:Bulk:AddVehicle",
//     "POST:Station:AddNozzle",
//     "GET:UserRoles:GetUsersAssignedToRole",
//     "Get:Authentication:GetAllUsers",
//     "Get:Authentication:GetAllUsers",
//     "GET:Station:GetAllDispenserAssignments",
//     "POST:Setup:AddPrice",
//     "PATCH:Customer:ChangeComplainStatus",
//     "GET:Bulk:BulkVehicleListing",
//     "Post:Authentication:AssignUserToApp",
//     "Post:Authentication:AssignUserToApp",
//     "Post:Authentication:DeactivateUser",
//     "Post:Authentication:DeactivateUser",
//     "POST:Setup:ViewProducts",
//     "POST:Bulk:OffLoading",
//     "GET:Bulk:BulkOrders",
//     "GET:UserRoles:GetUserRoles",
//     "POST:Bulk:UpdateVehicle",
//     "Post:Authentication:RegisterUser",
//     "Post:Authentication:RegisterUser",
//     "POST:Setup:AddProduct",
//     "Post:Authentication:RemoveUserFromApp",
//     "Post:Authentication:RemoveUserFromApp",
//     "POST:Station:AddDispenser",
//     "Post:Authentication:ActivateUser",
//     "Post:Authentication:ActivateUser",
//     "POST:Station:UnAssignDispenserAsync",
//     "POST:Setup:AddPaymentType",
//     "POST:Bulk:UpdateCustomerStatus",
//     "POST:Bulk:SearchVehicleByRegNumber",
//     "GET:UserRoles:GetUserPermissions",
//     "POST:UserRoles:AssignRoleToUser",
//     "Get:Authentication:GetUserApps",
//     "Get:Authentication:GetUserApps",
//     "POST:Bulk:AddCustomer",
//     "GET:Bulk:AgentOrders",
//     "POST:Sales:SalesShiftSummarySummary",
//     "POST:UserRoles:AddUserRoles"
// ],
//     "exp": 1725147698
// }