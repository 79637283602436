import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import { Link } from "react-router-dom";
import CustomerContext from "../../context/bulk/customers/customersContext";
import Spinner from "../../components/Layout/spinner";
import Pagination from "../../components/Layout/paginator";

const ListVehicles = () => {
    const customerContext = useContext(CustomerContext);
    const { vehicles, listVehicles, searchVehicle } = customerContext;

    useEffect(() => {
        listVehicles();
    }, []);

    const page = "List Vehicles";

    const [search, setSearch] = useState("");
    const [statusFilter, setStatusFilter] = useState("All");
    const [currentPage, setCurrentPage] = useState(1);
    const [vehiclesPerPage] = useState(15); // Number of vehicles to display per page
    const [loading, setLoading] = useState(false); // Loading state for the spinner

    // Handle search input change
    const onSearch = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1); // Reset to the first page on search
        setLoading(true); // Start loading when search begins

        // Simulate a delay for the search process (e.g., API call)
        setTimeout(() => {
            setLoading(false); // Stop loading when search is complete
        }, 500);
    };

    // Handle status filter change
    const onStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
        setCurrentPage(1); // Reset to the first page on filter change
    };

    // Filter vehicles based on search query and status filter
    const filteredVehicles = vehicles.filter((vehicle) => {
        const matchesSearch = vehicle.registrationNumber
            .toLowerCase()
            .includes(search.toLowerCase());
        const matchesStatus =
            statusFilter === "All" ||
            (statusFilter === "Active" && vehicle.isActive) ||
            (statusFilter === "Inactive" && !vehicle.isActive);
        return matchesSearch && matchesStatus;
    });

    // Pagination logic
    const indexOfLastVehicle = currentPage * vehiclesPerPage;
    const indexOfFirstVehicle = indexOfLastVehicle - vehiclesPerPage;
    const currentVehicles = filteredVehicles.slice(
        indexOfFirstVehicle,
        indexOfLastVehicle
    );

    const totalPages = Math.ceil(filteredVehicles.length / vehiclesPerPage);

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page={page} />
            <Main>
                <div className="card full-size-card">
                    <div className="card-body">
                        {/* Title and Add Button */}
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5 className="card-title">List Vehicles</h5>
                            <Link
                                className="btn btn-primary float-end"
                                id="add-vehicle"
                                to="/bulk/vehicles/add"
                            >
                                Add Vehicle
                            </Link>
                        </div>

                        {/* Search and Status Filter in the same row */}
                        <div className="row mb-3">
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="search"
                                    value={search}
                                    onChange={onSearch}
                                    placeholder="Search Vehicles"
                                />
                            </div>
                            <div className="col-md-4">
                                <select
                                    className="form-select"
                                    value={statusFilter}
                                    onChange={onStatusFilterChange}
                                >
                                    <option value="All">All</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>
                        </div>

                        {loading ? (
                            <div className="text-center">
                                <Spinner />
                            </div>
                        ) : (
                            <table className="table datatable">
                                <thead>
                                    <tr>
                                        <th scope="col">Vehicle Id</th>
                                        <th scope="col">Reg No</th>
                                        <th scope="col">Tank Capacity</th>
                                        <th scope="col">Tare Weight</th>
                                        <th scope="col">Vehicle Type</th>
                                        <th scope="col">Vehicle Make</th>
                                        <th scope="col">Vehicle Model</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentVehicles.length > 0 ? (
                                        currentVehicles.map((vehicle, index) => (
                                            <tr key={vehicle.id || index}>
                                                <th scope="row">
                                                    {vehicle.id || "-"}
                                                </th>
                                                <td>
                                                    {vehicle.registrationNumber ||
                                                        "-"}
                                                </td>
                                                <td>
                                                    {vehicle.tankCapacity || "-"}
                                                </td>
                                                <td>
                                                    {vehicle.tareWeight || "-"}
                                                </td>
                                                <td>
                                                    {vehicle.vehicleType || "-"}
                                                </td>
                                                <td>
                                                    {vehicle.vehicleMake || "-"}
                                                </td>
                                                <td>
                                                    {vehicle.vehicleModel || "-"}
                                                </td>
                                                <td>
                                                    <span
                                                        className={`badge rounded-pill px-2 py-1 ${
                                                            vehicle.isActive
                                                                ? "bg-success text-white"
                                                                : "bg-danger text-white"
                                                        }`}
                                                    >
                                                        {vehicle.isActive
                                                            ? "Active"
                                                            : "Inactive"}
                                                    </span>
                                                </td>
                                                <td className="text-center">
                                                    <div className="dropdown">
                                                        <button
                                                            className="btn btn-light btn-sm dropdown-toggle"
                                                            type="button"
                                                            id="dropdownMenuButton1"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            Actions
                                                        </button>

                                                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                                            <li>
                                                                <a
                                                                    className="dropdown-item d-flex align-items-center"
                                                                    href={`/bulk/vehicles/${vehicle.registrationNumber}/edit`}
                                                                >
                                                                    <i className="bi bi-gear"></i>
                                                                    <span>
                                                                        Edit Vehicle
                                                                    </span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan="9"
                                                className="text-center"
                                            >
                                                No vehicles found.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}

                        <Pagination
                            itemsPerPage={vehiclesPerPage}
                            totalItems={filteredVehicles.length}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            totalPages={totalPages}
                        />
                    </div>
                </div>
            </Main>
        </div>
    );
};

export default ListVehicles;
