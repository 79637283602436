import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import { Link } from "react-router-dom";
import CustomerContext from "../../context/bulk/customers/customersContext";
import Spinner from "../../components/Layout/spinner";
import { formatDate } from "../../api/util";
import SweetAlertWrapper from "../../components/Layout/SweetAlert";
import AddLocation from "./AddLocation";
import Pagination from "../../components/Layout/paginator";

const ListCustomerLocations = () => {
    const customerContext = useContext(CustomerContext);
    const {
        customer_locations,
        getCustomerLocations,
        notification,
    } = customerContext;

    const [selectedCustomerId, setSelectedCustomerId] = useState("");
    const [showAddLocationModal, setShowAddLocationModal] = useState(false);
    const [search, setSearch] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(15);

    const handleShowAddLocation = () => {
        setShowAddLocationModal(true);
    };

    const handleClose = () => {
        setShowAddLocationModal(false);
    };

    useEffect(() => {
        getCustomerLocations();
    }, []);

    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    const onSearch = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1); // Reset to the first page on search
    };

    const filteredCustomerLocations = customer_locations.filter((location) => {
        const searchTerm = search.toLowerCase();
        return (
            location &&
            (location.locationName.toLowerCase().includes(searchTerm) ||
                location.customerName.toLowerCase().includes(searchTerm) ||
                location.customerPhone.toLowerCase().includes(searchTerm))
        );
    });

    const indexOfLastLocation = currentPage * itemsPerPage;
    const indexOfFirstLocation = indexOfLastLocation - itemsPerPage;
    const currentLocations = filteredCustomerLocations.slice(
        indexOfFirstLocation,
        indexOfLastLocation
    );

    const totalPages = Math.ceil(
        filteredCustomerLocations.length / itemsPerPage
    );

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page="List Customer Locations" />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={() => setShowAlert(false)}
                    confirmBtnText="OK"
                    type={alertMessage.type}
                />
                <AddLocation
                    show={showAddLocationModal}
                    handleClose={handleClose}
                />

                <div className="card full-size-card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5 className="card-title">
                                List Customer Locations
                            </h5>
                            <button
                                className="btn btn-primary float-end"
                                id="add-location"
                                onClick={handleShowAddLocation}
                            >
                                Add Customer Location
                            </button>
                        </div>

                        <form className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="search"
                                value={search}
                                onChange={onSearch}
                                placeholder="Search"
                            />
                        </form>

                        <table className="table datatable">
                            <thead>
                                <tr>
                                    <th scope="col">Location ID</th>
                                    <th scope="col">Location Name</th>
                                    <th scope="col">Customer Name</th>
                                    <th scope="col">Customer Phone</th>
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentLocations.length > 0 ? (
                                    currentLocations.map((location) => (
                                        <tr key={location.customerLocationId}>
                                            <th>
                                                {location.customerLocationId}
                                            </th>
                                            <td>{location.locationName}</td>
                                            <td>{location.customerName}</td>
                                            <td>{location.customerPhone}</td>
                                            <td className="dropdown">
                                                <button
                                                    className="btn btn-light btn-sm dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    Actions
                                                </button>
                                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                                    <li>
                                                        <button
                                                            className="dropdown-item d-flex align-items-center"
                                                            onClick={() =>
                                                                handleShowAddLocation()
                                                            }
                                                        >
                                                            <i className="bi bi-pencil-square"></i>
                                                            <span>Edit</span>
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item d-flex align-items-center"
                                                            href={`/bulk/customers/${location.customerId}`}
                                                        >
                                                            <i className="bi bi-eye"></i>
                                                            <span>
                                                                View Customer
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="5"
                                            className="text-center"
                                        >
                                            No Customer Locations found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={filteredCustomerLocations.length}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            totalPages={totalPages}
                        />
                    </div>
                </div>
            </Main>
        </div>
    );
};

export default ListCustomerLocations;
