import React from "react";
import { Link } from "react-router-dom";

function Sidebar({ page }) {
    return (
        <React.Fragment>
            <div className="niceadmin">
                <aside id="sidebar" className="sidebar">
                    <ul className="sidebar-nav" id="sidebar-nav">

                        {/* Dashboard */}
                        <li className="nav-item">
                            <Link className="nav-link" to="/">
                                <i className="bi bi-house-door-fill"></i>
                                <span>Dashboard</span>
                            </Link>
                        </li>

                        {/* Customers */}
                        <li className="nav-item">
                            <Link className="nav-link" to="/bulk/customers">
                                <i className="bi bi-people-fill"></i>
                                <span>Customers</span>
                            </Link>
                        </li>

                        {/* Customer Locations */}
                        <li className="nav-item">
                            <Link className="nav-link" to="/bulk/locations">
                                <i className="bi bi-geo-alt-fill"></i>
                                <span>Customer Locations</span>
                            </Link>
                        </li>

                        {/* Vehicles */}
                        <li className="nav-item">
                            <Link className="nav-link" to="/bulk/vehicles">
                                <i className="bi bi-truck"></i>
                                <span>Vehicles</span>
                            </Link>
                        </li>

                        {/* Orders */}
                        <li className="nav-item">
                            <Link className="nav-link" to="/bulk/orders">
                                <i className="bi bi-receipt"></i>
                                <span>Orders</span>
                            </Link>
                        </li>

                        {/* Drivers */}
                        <li className="nav-item">
                            <a
                                className="nav-link collapsed"
                                data-bs-target="#drivers-nav"
                                data-bs-toggle="collapse"
                                href="#"
                            >
                                <i className="bi bi-person-circle"></i>
                                <span>Drivers</span>
                                <i className="bi bi-chevron-down ms-auto"></i>
                            </a>
                            <ul
                                id="drivers-nav"
                                className="nav-content collapse"
                                data-bs-parent="#sidebar-nav"
                            >
                                <li>
                                    <a href="/bulk/drivers">
                                        <i className="bi bi-circle-fill"></i>
                                        <span>Drivers</span>
                                    </a>
                                </li>
                            </ul>
                        </li>

                        {/* Delivery Plans */}
                        <li className="nav-item">
                            <a
                                className="nav-link collapsed"
                                data-bs-target="#tripplanning-nav"
                                data-bs-toggle="collapse"
                                href="#"
                            >
                                <i className="bi bi-calendar-check-fill"></i>
                                <span>Delivery Plans</span>
                                <i className="bi bi-chevron-down ms-auto"></i>
                            </a>
                            <ul
                                id="tripplanning-nav"
                                className="nav-content collapse"
                                data-bs-parent="#sidebar-nav"
                            >
                                <li>
                                    <a href="/bulk/delivery-plans">
                                        <i className="bi bi-circle-fill"></i>
                                        <span>Delivery Plans</span>
                                    </a>
                                </li>
                            </ul>
                        </li>

                        {/* Staff Management */}
                        <li className="nav-item">
                            <a
                                className="nav-link collapsed"
                                data-bs-target="#staff-management-nav"
                                data-bs-toggle="collapse"
                                href="#"
                            >
                                <i className="bi bi-person-badge-fill"></i>
                                <span>Staff Management</span>
                                <i className="bi bi-chevron-down ms-auto"></i>
                            </a>
                            <ul
                                id="staff-management-nav"
                                className="nav-content collapse"
                                data-bs-parent="#sidebar-nav"
                            >
                                <li>
                                    <a href="/users">
                                        <i className="bi bi-circle-fill"></i>
                                        <span>Users</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/users/roles">
                                        <i className="bi bi-circle-fill"></i>
                                        <span>Roles</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/users/profile">
                                        <i className="bi bi-circle-fill"></i>
                                        <span>Profile</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </aside>
            </div>
        </React.Fragment>
    );
}

export default Sidebar;
