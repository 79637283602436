import React, { useState, useEffect, useContext } from "react";
import Header from "../../components/Layout/Header";
import Sidebar from "../../components/Layout/Sidebar";
import Main from "../../components/Layout/Main";
import { Link } from "react-router-dom";
import CustomerContext from "../../context/bulk/customers/customersContext";
import Spinner from "../../components/Layout/spinner";
import AddCustomerLocation from "./addCutomerLocation";
import EditCustomerModal from "./editCustomer";
import { formatDate } from "../../api/util";
import SweetAlertWrapper from "../../components/Layout/SweetAlert";
import Pagination from "../../components/Layout/paginator";

const ListCustomers = () => {
    const customerContext = useContext(CustomerContext);
    const { customers, getCustomers, notification } = customerContext;

    const [selectedCustomerId, setSelectedCustomerId] = useState("");
    const [showAddLocationModal, setShowAddLocationModal] = useState(false);
    const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [search, setSearch] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ text: "", type: "" });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(15);

    const handleShowAddLocation = (id) => {
        setSelectedCustomerId(id);
        setShowAddLocationModal(true);
    };

    const handleClose = () => {
        setSelectedCustomerId("");
        setShowAddLocationModal(false);
        setShowEditCustomerModal(false);
        ListCustomers()
    };

    const handleShowEditCustomer = (customer) => {
        setSelectedCustomer(customer);
        setShowEditCustomerModal(true);
    };

    useEffect(() => {
        const fetchCustomers = async () => {
            setLoading(true);
            setError(null);
            try {
                await getCustomers();
            } catch (err) {
                setError("Failed to fetch customers. Please try again.");
            } finally {
                setLoading(false);
            }
        };
        fetchCustomers();
    }, []);

    useEffect(() => {
        if (notification) {
            setAlertMessage({
                text: notification.text,
                type: notification.type,
            });
            setShowAlert(true);
        }
    }, [notification]);

    const onSearch = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1); // Reset to the first page on search
    };

    const filteredCustomers = customers.filter((customer) => {
        const searchTerm = search.toLowerCase();
        return (
            customer.customerName.toLowerCase().includes(searchTerm) ||
            customer.customerEmail.toLowerCase().includes(searchTerm) ||
            customer.customerPhone.toLowerCase().includes(searchTerm) ||
            customer.krapin.toLowerCase().includes(searchTerm)
        );
    });

    const indexOfLastCustomer = currentPage * itemsPerPage;
    const indexOfFirstCustomer = indexOfLastCustomer - itemsPerPage;
    const currentCustomers = filteredCustomers.slice(
        indexOfFirstCustomer,
        indexOfLastCustomer
    );

    const totalPages = Math.ceil(filteredCustomers.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="niceadmin">
            <Header />
            <Sidebar page="List Customer" />
            <Main>
                <SweetAlertWrapper
                    show={showAlert}
                    title="Notification"
                    message={alertMessage.text}
                    onConfirm={() => setShowAlert(false)}
                    confirmBtnText="OK"
                    type={alertMessage.type}
                />
                <AddCustomerLocation
                    customerId={selectedCustomerId}
                    show={showAddLocationModal}
                    handleClose={handleClose}
                />

                <EditCustomerModal
                    customer={selectedCustomer}
                    show={showEditCustomerModal}
                    handleClose={handleClose}
                />

                <div className="card full-size-card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5 className="card-title">List Customers</h5>
                            <Link
                                className="btn btn-primary float-end"
                                id="add-user"
                                to="/bulk/customers/add"
                            >
                                Add Customer
                            </Link>
                        </div>

                        <form className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="search"
                                value={search}
                                onChange={onSearch}
                                placeholder="Search"
                            />
                        </form>

                        {loading ? (
                            <div className="text-center mt-4">
                                <Spinner />
                            </div>
                        ) : error ? (
                            <div className="alert alert-danger">{error}</div>
                        ) : (
                            <table className="table datatable">
                                <thead>
                                    <tr>
                                        <th scope="col">Customer ID</th>
                                        <th scope="col">Customer Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Phone Number</th>
                                        <th scope="col">KRA PIN</th>
                                        <th scope="col">Date Created</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentCustomers.length > 0 ? (
                                        currentCustomers.map((customer) => (
                                            <tr key={customer.customerId}>
                                                <th>
                                                    {customer.customerId}
                                                </th>
                                                <td>
                                                    {customer.customerName}
                                                </td>
                                                <td>
                                                    {customer.customerEmail}
                                                </td>
                                                <td>
                                                    {customer.customerPhone}
                                                </td>
                                                <td>{customer.krapin}</td>
                                                <td>
                                                    {formatDate(
                                                        customer.dateCreated
                                                    )}
                                                </td>
                                                <td className="dropdown">
                                                    <button
                                                        className="btn btn-light btn-sm dropdown-toggle"
                                                        type="button"
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        Actions
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                                                        <li>
                                                            <Link
                                                                className="dropdown-item d-flex align-items-center"
                                                                onClick={() =>
                                                                    handleShowAddLocation(
                                                                        customer.customerId
                                                                    )
                                                                }
                                                            >
                                                                <i className="bi bi-person"></i>
                                                                <span>
                                                                    Set Location
                                                                </span>
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <a
                                                                className="dropdown-item d-flex align-items-center"
                                                                href={`/bulk/customers/${customer.customerId}`}
                                                            >
                                                                <i className="bi bi-eye"></i>
                                                                <span>
                                                                    View
                                                                    Customer
                                                                </span>
                                                            </a>
                                                        </li>

                                                        <li>
                                                            <Link
                                                                className="dropdown-item d-flex align-items-center"
                                                                onClick={() =>
                                                                    handleShowEditCustomer(
                                                                        customer
                                                                    )
                                                                }
                                                            >
                                                                <i className="bi bi-pencil-square"></i>
                                                                <span>
                                                                    Edit
                                                                    Customer
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan="7"
                                                className="text-center"
                                            >
                                                {search
                                                    ? "No customers match your search criteria."
                                                    : "No customers found."}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        )}

                        <Pagination
                            itemsPerPage={itemsPerPage}
                            totalItems={filteredCustomers.length}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setCurrentPage={setCurrentPage}
                        />
                    </div>
                </div>
            </Main>
        </div>
    );
};

export default ListCustomers;
