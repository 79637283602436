import {
    ADD_ORDER,
    DELETE_ORDER,
    SET_CURRENT,
    CLEAR_CURRENT,
    UPDATE_ORDER,
    FILTER_ORDERS,
    CLEAR_FILTER,
    ORDER_ERROR,
    GET_ORDERS,
    CLEAR_ORDERS,
    CLEAR_NOTIFICATION,
    LIST_DELIVERY_PLANS,
    ATTACH_ORDER,
    OFFLOAD_ORDER,
    LIST_PRODUCTS,
    GET_DASHBOARD_DATA,
    GET_UNASSIGNED_ORDERS
} from "../../types";

export default (state, action) => {
    switch (action.type) {
        case GET_ORDERS:
            return {
                ...state,
                orders: action.payload,
                loading: false
            };
        case ADD_ORDER:
            console.log(action.payload);
            return {
                ...state,
                notification: {
                    text: action.payload,
                    type: "success"
                },
                loading: false,
            };

        case UPDATE_ORDER:
            return {
                ...state,
                orders: state.orders.map(order =>
                    order._id === action.payload._id ? action.payload : order
                ), //order obj
                loading: false
            };
        case DELETE_ORDER:
            return {
                ...state,
                orders: state.orders.filter(order => {
                    return order._id !== action.payload; //payload is the id
                }),
                loading: false
            };
        case SET_CURRENT:
            return {
                ...state,
                current: action.payload //order obj
            };

        case CLEAR_CURRENT:
            return {
                ...state,
                current: null
            };
        case FILTER_ORDERS:
            return {
                ...state,
                filtered: state.orders.filter(order => {
                    const regex = new RegExp(`${action.payload}`, "gi");
                    return order.name.match(regex) || order.email.match(regex);
                })
            };
        case CLEAR_FILTER:
            return {
                ...state,
                filtered: null
            };
        case ORDER_ERROR: {
            return {
                ...state,
               notification: {
                    text: action.payload,
                    type: "error"
                },
                loading: false
            };
        }
        case CLEAR_ORDERS: {
            return {
                ...state,
                orders: null,
                filtered: null,
                error: null,
                current: null
            };
        }
        case CLEAR_NOTIFICATION:
            return {
                ...state,
                notification: null
            };

        case LIST_DELIVERY_PLANS: 
            return {
                ...state,
                delivery_plans: action.payload.deliveryPlans,
                totalRecords: action.payload.totalRecords,
                loading: false
            }
        case ATTACH_ORDER:
            return {
                ...state,
                notification: {
                    text: action.payload,
                    type: "success"
                },
                loading: false,
            };
        case OFFLOAD_ORDER: 
            return {
                ...state,
                notification: {
                    text: action.payload,
                    type: "success"
                },
                loading: false,
            }

        case LIST_PRODUCTS:
            return {
                ...state,
                products: action.payload
            }

        case GET_DASHBOARD_DATA:
            return {
                ...state,
                dashBoardData: action.payload
            }

        case GET_UNASSIGNED_ORDERS: 
            return {
                ...state,
                unassignedOrders: action.payload
            }
        case DELETE_ORDER:
            return {
                ...state,
                notification: {
                    text: action.payload,
                    type: "success"
                }
            }
        
        default:
            return state;
    }
};