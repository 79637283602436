export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const ORDER_ERROR = "ORDER_ERROR";
export const GET_ORDERS = "GET_ORDERS";
export const CLEAR_ORDERS = "CLEAR_ORDERS";
export const ADD_ORDER = "ADD_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const FILTER_ORDERS = "FILTER_ORDERS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER" 
export const USER_ERROR = "USER_ERROR";
export const ADD_USER = "ADD_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const FILTER_USERS = "FILTER_USERS";
export const CLEAR_USERS = "CLEAR_USERS";
export const CUSTOMER_ERROR = "CUSTOMER_ERROR";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const CLEAR_CUSTOMERS = "CLEAR_CUSTOMERS";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE"
export const SET_CURRENT_CUSTOMER = "SET_CURRENT_CUSTOMER";
export const CLEAR_CURRENT_CUSTOMER = "CLEAR_CURRENT_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER"
export const FILTER_CUSTOMERS = "FILTER_CUSTOMERS";
export const LIST_CUSTOMERS = "LIST_CUSTOMERS";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_AGENT_ORDERS = "GET_AGENT_ORDERS";
export const GET_AGENT_ORDERS_ERROR = "GET_AGENT_ORDERS_ERROR";
export const GET_DRIVER_ORDERS = "GET_DRIVER_ORDERS";
export const DRIVER_ORDERS_ERROR = "GET_DRIVER_ORDERS_ERROR";
export const ADD_CUSTOMER_LOCATION = "ADD_CUSTOMER_LOCATION";
export const AGENT_ORDERS_ERROR = "AGENT_ORDERS_ERROR";
export const SEARCH_CUSTOMER = "SEARCH_CUSTOMER";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const LIST_APPS = "LIST_APPS";
export const LIST_PRODUCTS = "LIST_PRODUCTS";
export const LIST_VEHICLES = "LIST_VEHICLES";
export const LIST_DELIVERY_PLANS = "LIST_DELIVERY_PLANS";
export const LIST_DRIVERS = "LIST_DRIVERS";
export const SEARCH_VEHICLE = "SEARCH_VEHICLE";
export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const ATTACH_ORDER = "ATTACH_ORDER";
export const OFFLOAD_ORDER = "OFFLOAD_ORDER";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const GET_UNASSIGNED_ORDERS =  "GET_UNASSIGNED_ORDERS";
export const GET_CUSTOMERS_LOCATIONS = "GET_CUSTOMERS_LOCATIONS";
export  const LIST_USER_ROLES = "LIST_USER_ROLES";
export const LIST_ROLES = "LIST_ROLES";
export const ASSIGN_ROLES = "ASSIGN_ROLES";
export const REMOVE_ROLES = "REMOVE_ROLES";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const GET_USER_DETAILS_FROM_TOKEN = "GET_USER_DETAILS_FROM_TOKEN";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_WARNING = "AUTH_WARNING";
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";
export const LIST_ROLE_PERMISSIONS = "LIST_ROLE_PERMISSIONS";
export const LIST_ROLE_USERS = "LIST_ROLE_USERS";






